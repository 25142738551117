<template>
  <div id="signup">

    <SignupContent
      :mode="mode"
    />

    <Footer />

    <FullscreenSpinner />

  </div>
</template>


<script>
import SignupContent from '@/components/sections/TheSignupContent'
import Footer from '@/components/sections/TheFooter'

export default {

  name: 'Signup',

  components: {
    SignupContent,
    Footer,
  },

  props: {
    mode: {
      type: String,
      default: 'signup'
    },
  },
}
</script>


<style scoped>
#signup {
  display: flex;
  flex-direction: column;
}
</style>