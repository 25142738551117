<template>
  <div id="dpContent">
    
    <h1>
      Datenschutz und Vertraulichkeit Ihrer Angaben
    </h1>

    <div>
      Für forsa ist es selbstverständlich, verantwortungsbewusst mit persönlichen Daten umzugehen und diese nur nach den Maßgaben des einschlägigen <b>deutschen und europäischen Datenschutzrechts</b> zu verwenden.
    </div>
    <div>
      Alle personenbezogenen Daten werden auf unseren unternehmenseigenen Servern im Inland gespeichert, die durch technische und organisatorische Maßnahmen vor unberechtigtem Zugriff geschützt sind.
    </div>
    <div>
      Ihre Teilnahme an Interviews ist freiwillig. Bei Nicht-Teilnahme entstehen Ihnen keinerlei Nachteile.
    </div>
    <div>
      Zur Teilnahme an forsa.omninet werden Ihre Kontaktdaten bei forsa gespeichert. Sie haben jederzeit die Möglichkeit, Ihre Teilnahme an forsa.omninet zu beenden und die <b>Löschung Ihrer Daten</b> zu verlangen. Der Datenschutzbeauftragte der forsa ist unter <a href="mailto:datenschutz@forsa.de"><span>datenschutz@forsa.de</span></a> erreichbar.
    </div>
    <div>
      forsa führt Ihren Namen und Ihre Anschrift nicht mit Interviewdaten zusammen, so dass kein Dritter erfährt, welche Antworten Sie gegeben haben. forsa gibt Ihren Namen und Ihre Anschrift natürlich nicht an Dritte weiter.
    </div>
    <div>
      Für weitere Informationen zum Datenschutz klicken Sie bitte <a href="https://www.forsa.de/datenschutzerklaerung/" target="_blank"><span>hier</span></a>.
    </div>

  </div>
</template>


<script>

export default {

  name: 'DataProtection',
}
</script>


<style scoped>
#dpContent > * {
  margin-bottom: 24px;
  hyphens: auto;
  max-width: 800px;
}
#dpContent > h1 {
  margin-bottom: 48px;
}
</style>