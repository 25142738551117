<template>
  <div id="content">

    <SignupCodeInput
      v-if="!code || !codeValid"
      :mode="mode"
      @codeValid="afterCodeInput"
    />

    <HorizontalContainer
      v-if="code && codeValid"
      class="progressContainer"
    >
      <div class="progressWrapper">
        <AppProgress
          id="progress"
          type="circle"
          :strokeWidth="4"
          backgroundColor="#efefee"
          :value="progress"
        >
          {{ Math.round(progress) }}%
        </AppProgress>
      </div>
    </HorizontalContainer>

    <!-- <transition-group
      v-if="code && codeValid"
      :name="'slide-' + slideDirection"
      tag="div" 
      id="slideWrapper"
    > -->
    <div
      v-if="code && codeValid"
      id="slideWrapper"
    >
      <SignupWelcome
        v-if="show === 0"
        key="slide-welcome"
        :continued="isContinued()"
        @next="saveAndNextSlide"
      />

      <SignupTopics
        v-if="show === 1"
        key="slide-topics"
        @back="showPrvSlide"
        @next="saveAndNextSlide"
        v-model="signupData"
      />

      <SignupPerson
        v-if="show === 2"
        key="slide-person"
        @next="saveAndNextSlide"
        @back="showPrvSlide"
        v-model="signupData"
      />

      <SignupEducation
        v-if="show === 3"
        key="slide-education"
        @next="saveAndNextSlide"
        @back="showPrvSlide"
        v-model="signupData"
      />

      <SignupResidence
        v-if="show === 4"
        key="slide-residence"
        @next="saveAndNextSlide"
        @back="showPrvSlide"
        v-model="signupData"
      />

      <SignupEmail
        v-if="show === 5"
        key="slide-email"
        @next="saveAndNextSlide"
        @back="showPrvSlide"
        v-model="signupData"
      />

      <SignupCredentials
        v-if="show === 6"
        key="slide-password"
        @next="saveAndNextSlide"
        @back="showPrvSlide"
        v-model="signupData"
      />

      <SignupDataProtection
        v-if="show === 7"
        key="slide-data-protection"
        @next="showNextSlide"
        @back="showPrvSlide"
      />

      <SignupFirstName
        v-if="show === 8"
        key="slide-first-name"
        @next="saveAndNextSlide"
        @back="showPrvSlide"
        v-model="signupData"
      />

      <SignupLastName
        v-if="show === 9"
        key="slide-last-name"
        @next="saveAndNextSlide"
        @back="showPrvSlide"
        v-model="signupData"
      />

      <SignupIncentiveModel
        v-if="show === 10"
        key="slide-incentive-model"
        @next="saveAndNextSlide"
        @back="showPrvSlide"
        v-model="signupData"
      />

      <SignupFinish
        v-if="show === 11"
        key="slide-finish"
        @finish="finishSignup"
        @back="showPrvSlide"
      />
    </div>
    <!-- </transition-group> -->

  </div>
</template>


<script>
import AppProgress from '@forsa/vue-progress-indicator'

import HorizontalContainer from '@/components/wrapper/HorizontalContainer'

import SignupCodeInput from './signup/CodeInput'

import SignupWelcome from './signup/Welcome'
import SignupTopics from './signup/Topics'
import SignupPerson from './signup/Person'
import SignupEducation from './signup/Education'
import SignupResidence from './signup/Residence'
import SignupEmail from './signup/Email'
import SignupCredentials from './signup/Credentials'
import SignupDataProtection from './signup/DataProtection'
import SignupFirstName from './signup/FirstName'
import SignupLastName from './signup/LastName'
import SignupIncentiveModel from './signup/IncentiveModel'
import SignupFinish from './signup/Finish'

export default {

  name: 'SignupContent',

  components: {
    AppProgress,
    HorizontalContainer,
    SignupWelcome,
    SignupCodeInput,
    SignupTopics,
    SignupPerson,
    SignupEducation,
    SignupResidence,
    SignupEmail,
    SignupCredentials,
    SignupDataProtection,
    SignupFirstName,
    SignupLastName,
    SignupIncentiveModel,
    SignupFinish,
  },

  data(){
    return {
      code: this.$route.params.code,
      codeValid: true,
      show: null,
      slideCount: 12,
      slideDirection: 'fw',
      signupData: {},
    }
  },

  props: {
    mode: String,
  },

  computed: {

    progress(){

      let p = (this.show) / this.slideCount * 100;

      return p < 1 ? 0 : p > 100 ? 100 : p;
    },

    signupMode(){

      if (!this.codeValid || !this.code)
        return this.mode;

      if (this.code.length === 9)
        return 'referral';

      return 'signup';
    },
  },

  mounted(){

    if (this.code)
      this.validateCode()
  },

  methods: {

    afterCodeInput(code){

      this.code = code;

      this.validateCode();
    },

    validateCode(){

      this.appWorking('signup');

      $.ajax({
        method: 'POST',
        url: this.urls.api + 'validate-signup-code',
        data: {
          code: this.code,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.clearAppStatus();
      
          this.codeValid = true;

          window.history.pushState(
            null, 
            'forsa.omninet',
            '/signup/' + this.code
          );

          this.getSignupData();
        }
      )
      .fail(
        resp => {
      
          this.clearAppStatus();
      
          this.codeValid = false;

          if (resp.status === 408) {
            
            this.appError(
              'Der Werber-Code wurde bereits zu oft verwendet und wurde daher gesperrt.', 
              null, 
              'signup-code-input'
            );
          }
        }
      );
    },

    getSignupData(){

      this.appWorking('signup');
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'get-signup-data',
        data: {
          code: this.code,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.clearAppStatus();
      
          this.signupData = readJson(resp);

          this.show = 0;
        }
      )
      .fail(
        resp => {

          this.clearAppStatus();
      
          this.codeValid = false;
        }
      );
    },

    isContinued(){

      return Object.keys(this.signupData)
        .filter(k => !['code', 'gender', 'birthyear', 'email'].includes(k))
        .some(k => !!this.signupData[k])
    },

    saveAndNextSlide(step = 1){

      this.appWorking('signup');

      if (!this.code)
        this.code = window.location.pathname.split('/').pop();

      let data = Object.assign(
        this.signupData,
        {code: this.code}
      );
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'submit-signup-data',
        data: data,
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.clearAppStatus();
      
          this.showNextSlide(step);
        }
      )
      .fail(
        resp => {

          if (resp.status === 428) {

            this.appError(
              'Ein Fehler ist aufgetreten. Bitte laden Sie die Seite erneut. Falls das Problem weiterhin besteht, würden wir uns freuen, wenn Sie es in einigen Tagen noch einmal versuchen. Bitte entschuldigen Sie die Unannehmlichkeiten.', 
              null, 
              'signup'
            );

            return;
          }

          this.appError(
            'Beim Speichern Ihrer Antwort ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.', 
            null, 
            'signup'
          );
        }
      );
    },

    showNextSlide(step = 1){

      this.slideDirection = 'fw';

      this.show += step;
    },

    showPrvSlide(step = 1){

      this.clearAppStatus('signup');

      this.slideDirection = 'bw';

      this.show -= step;
    },

    finishSignup(){

      this.appWorking('signup');

      $.ajax({
        method: 'POST',
        url: this.urls.api + 'finish-signup',
        data: {
          code: this.code,
          mode: this.signupMode,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.clearAppStatus('signup');

          this.appSuccess('Die Registrierung bei forsa.omninet wurde erfolgreich abgeschlossen. Sie können sich nun mit Ihrem gewählten Benutzernamen oder Ihrer E-Mail-Adresse sowie Ihrem Passwort anmelden.');

          this.$router.push({name: 'portal'});
        }
      )
      .fail(
        resp => {

          this.appError('Bei der Registrierung ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.', null, 'signup');
        }
      );
    },
  },
}
</script>


<style>
#signup #content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
#signup #slideWrapper {
  flex-grow: 1;
  position: relative;
  overflow-y: hidden;
  /*height: 100%;*/
}
#signup #slideWrapper > .slide {
  /*position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;*/
  overflow-y: auto;
}
@keyframes slide-from-top {
  0% {
    top: -100%;
    bottom: 100%;
  }
  100% {
    top: 0;
    bottom: 0;
  }
}
@keyframes slide-from-bottom {
  0% {
    top: 100%;
    bottom: -100%;
  }
  100% {
    top: 0;
    bottom: 0;
  }
}
@keyframes slide-to-top {
  0% {
    top: 0;
    bottom: 0;
  }
  100% {
    top: -100%;
    bottom: 100%;
  }
}
@keyframes slide-to-bottom {
  0% {
    top: 0;
    bottom: 0;
  }
  100% {
    top: 100%;
    bottom: -100%;
  }
}
/*
#signup .slide-fw-enter-active {
  animation: slide-from-bottom .4s ease-out;
}
#signup .slide-fw-leave-active {
  animation: slide-to-top .4s ease-out;
}
#signup .slide-bw-enter-active {
  animation: slide-from-top .4s ease-out;
}
#signup .slide-bw-leave-active {
  animation: slide-to-bottom .4s ease-out;
}*/
#signup .slide.noBack >>> .button.back {
  visibility: hidden;
}
#signup .progressContainer {
  display: block !important;
  padding: 0 12px;
}
#signup .progressWrapper {
  position: relative;
}
#signup #progress > .circle {
  position: absolute;
  top: 24px;
  right: 12px;
  width: 72px;
  height: 72px;
}
#signup #progress .inside {
  font-size: 80%;
  color: #999;
}
@media only screen and (max-width: 884px) {
  #signup #progress .circle {
    width: 54px;
    height: 54px;
    top: 12px;
  }
  #signup #slideWrapper .hContainer {
    padding-top: 78px;
  }
}
</style>