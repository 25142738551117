<template>
  <SlideWrapper
    @next="$emit('next')"
    @back="$emit('back')"
  >

    <h1>
      Fast geschafft!
    </h1>

    <div>
      Der Schutz Ihrer persönlichen Daten ist forsa ganz besonders wichtig. 
      Lesen Sie hierzu bitte auch unsere Erklärung zum 
      <a
        @click="show = true"
      >
        <u>Datenschutz</u>
      </a>. Sie sollten wissen, dass Sie keinerlei Verpflichtung eingehen und 
      Ihre Teilnahme jederzeit beenden können.
    </div>

    <Popup
      v-if="show"
      @closePopup="show = false"
      width="752px"
      height="692px"
    >
      <DataProtectionContent />
    </Popup>
    
  </SlideWrapper>
</template>


<script>
import SlideWrapper from './SlideWrapper'
import DataProtectionContent from '../TheDataProtectionContent'

export default {

  name: 'SignupDataProtection',

  components: {
    SlideWrapper,
    DataProtectionContent,
  },

  data(){
    return {
      show: false,
    }
  },
}
</script>
