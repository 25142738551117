<template>
  <SlideWrapper
    @next="clickNext"
    @back="$emit('back')"
  >

    <h3>
      Bitte verraten Sie uns Ihre Postleitzahl und Ihren Wohnort.
    </h3>

    <div>
      Viele unserer Ergebnisse werden nach Faktoren wie der Ortsgröße, dem 
      Bundesland oder besonderen regionalen Faktoren ausgewertet. Einige 
      Studien werden auch nur in einigen bestimmten Regionen oder Bundesländern 
      durchgeführt, je nachdem, um welches Thema es geht. Daher ist es für uns 
      sehr wichtig, zu wissen, wo Sie <b>hauptsächlich</b> wohnen.
      <br /><br />
      Bitte tippen Sie hierzu entweder Ihre Postleitzahl oder den Namen Ihres 
      Wohnortes in das Feld ein und wählen Sie den zutreffenden Eintrag aus der 
      Liste aus.
    </div>

    <div class="grid">
      <AppAutocompleteInput
        v-model="input"
        :url="url"
        displayKey="display"
        placeholder="Postleitzahl oder Ort eintragen und aus der Liste auswählen"
        :asObject="true"
        @input="onResidenceChange"
      >
        Postleitzahl/Ort:
      </AppAutocompleteInput>
      <div>
        <i
          :class="'fas fa-fw ' + (valid ? 'fa-check-circle succ' : 'fa-exclamation-circle err')"
          style="font-size: 125%;"
        >
        </i>
      </div>
    </div>

  </SlideWrapper>
</template>


<script>
import SlideWrapper from './SlideWrapper'

export default {

  name: 'SignupResidence',

  components: {
    SlideWrapper,
  },

  data(){
    return {
      value: this.getValue(),
      input: this.getInput(),
    }
  },

  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    modelValue: Object,
  },

  watch: {

    modelValue(newVal, oldVal){

      this.value = this.getValue();
    },
  },

  computed: {

    url(){

      return this.urls.api + 'get-residence-suggestions';
    },

    valid(){

      return 'gkz' in this.value && this.value.gkz;
    },
  },

  methods: {

    getValue(){

      return Object.assign({}, this.$props.modelValue);
    },

    getInput(){

      return this.modelValue.residence || '';
    },

    onResidenceChange(){

      this.value.gkz = !this.input ? null : this.input.gkz;

      this.value.post_code = !this.input ? null : this.input.post_code;

      this.value.district = !this.input ? null : this.input.district;

      this.value.residence = !this.input ? null : this.input.display;

      this.$emit('change', this.value)
    },

    async clickNext(){

      if (await this.checkVal())
        this.$emit('next');
    },

    checkVal(){

      if (!this.valid) {

        this.appError('Bitte wählen Sie einen Eintrag aus der Liste aus, nachdem Sie Ihre Postleitzahl oder Ihren Ort eingegeben haben. Nachdem Sie einen Eintrag ausgewählt haben, sollten Sie diesen nicht mehr bearbeiten. Zur Orientierung sehen Sie rechts neben dem Eingabefeld ein grünes oder rotes Symbol, das Ihnen anzeigt, ob Ihre Eingabe gültig ist.', null, 'signup')

        return false;
      }

      return true;
    },
  },
}
</script>


<style scoped>
.grid {
  display: grid;
  grid-template-columns: auto 25px;
  grid-column-gap: 3px;
  align-items: center;
}
.input {
  display: grid;
  grid-template-columns: minmax(20%, 144px) auto;
  grid-column-gap: 6px;
}
.input >>> .suggestions {
  max-height: 144px;
  overflow-y: auto;
}
.input >>> input {
  padding-right: 27px;
}
.succ {
  color: #8bc34a;
}
.err {
  color: #cc0000;
}
</style>