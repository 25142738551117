<template>
  <SlideWrapper
    @next="$emit('finish')"
    @back="$emit('back')"
  >

    <h1>
      Jetzt kann es losgehen!
    </h1>

    <div>
      Wir leiten Sie nun weiter zu forsa.omninet. Dort wartet bereits die erste 
      Umfrage auf Sie.
    </div>

    <div>
      Immer wenn eine neue Befragung für Sie bereit steht, finden Sie diese dort 
      unter dem Menüpunkt &bdquo;Umfragen&ldquo;. Außerdem senden wir Ihnen 
      jeweils eine E-Mail-Einladung, so dass Sie keine Umfrage verpassen können.
    </div>

  </SlideWrapper>
</template>


<script>
import SlideWrapper from './SlideWrapper'

export default {

  name: 'SignupFinish',

  components: {
    SlideWrapper,
  },
}
</script>
