<template>
  <HorizontalContainer>

    <h1>
      Willkommen bei forsa!
    </h1>

    <div
      v-if="mode === 'referral'"
    >
      Wir freuen uns, dass Sie der Empfehlung Ihrer Freundin, Ihres Freundes 
      oder Ihres Familien-Mitglieds gefolgt sind und Interesse haben, an unseren 
      Online-Umfragen teilzunehmen. Bei forsa.omninet fragen wir Sie zum 
      Beispiel nach Ihrer Meinung zu aktuellen Themen oder auch nach Ihren 
      Erwartungen an bestimmte Produkte oder Dienstleistungen.			
    </div>

    <div
      v-if="mode === 'referral'"
    >
      Bitte geben Sie den Werber-Code ein, den Sie erhalten haben.
    </div>

    <div
      v-else
    >
      Bitte geben Sie den Registrierungs-Code aus Ihrer Bestätigungs-E-Mail 
      bzw. -SMS ein.
    </div>

    <StatusMessage
      scope="signup-code-input"
      v-if="scopedStatus['signup-code-input'] > 1"
    />

    <AppInput
      v-model="code"
      :placeholder="mode === 'referral' ? 'Werber-Code' : 'Registrierungs-Code'"
      :maxlength="mode === 'referral' ? 9 : 12"
      class="noTitle"
      @enterPressed="validateCode"
    />

    <AppButton
    @click="validateCode"
      cls="material"
    >
      Absenden
    </AppButton>

  </HorizontalContainer>
</template>


<script>
import HorizontalContainer from '@/components/wrapper/HorizontalContainer'

export default {

  name: 'SignupCodeInput',

  components: {
    HorizontalContainer,
  },

  data(){
    return {
      code: null,
    }
  },

  props: {
    mode: String,
  },

  methods: {

    validateCode(){

      if (!this.code) {

        this.appError('Bitte geben Sie den Code in das Eingabefeld ein.', null, 'signup-code-input')

        return false;
      }

      this.appWorking('signup-code-input')
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'validate-signup-code',
        data: {
          code: this.code,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.clearAppStatus()
      
          this.$emit('codeValid', this.code)
        }
      )
      .fail(
        resp => {
      
          if (resp.status === 408)
            this.appError('Der eingegebene Werber-Code wurde bereits zu oft verwendet und wurde daher gesperrt.', null, 'signup-code-input');
          else
            this.appError('Der eingegebene Code ist leider nicht gültig.', null, 'signup-code-input');
        }
      );
    },

  },
}
</script>


<style scoped>
.hContainer {
  padding: 48px 12px 24px 12px;
}
.hContainer > * {
  max-width: 800px;
  margin: 0 0 24px 0;
}
.hContainer > .button {
  text-align: center;
}
</style>