<template>
  <SlideWrapper
    @next="clickNext"
    @back="$emit('back')"
  >

    <h1>
      Los geht's:
    </h1>

    <h3>
      Forsa führt regelmäßig Umfragen zu sehr vielen unterschiedlichen Themen 
      durch. Für welche der folgenden Themenbereiche interessieren Sie sich 
      besonders?
    </h3>

    <div class="grid">
      <AppCheckbox
        v-model="value.topics_1"
        :value="1"
        :uncheckValue="null"
        @change="$emit('change', value)"
      >
        Politik &amp; Wahlen
      </AppCheckbox>

      <AppCheckbox
        v-model="value.topics_2"
        :value="1"
        :uncheckValue="null"
        @change="$emit('change', value)"
      >
        Produkte &amp; Innovationen
      </AppCheckbox>

      <AppCheckbox
        v-model="value.topics_3"
        :value="1"
        :uncheckValue="null"
        @change="$emit('change', value)"
      >
        Freizeit &amp; Reisen
      </AppCheckbox>

      <AppCheckbox
        v-model="value.topics_4"
        :value="1"
        :uncheckValue="null"
        @change="$emit('change', value)"
      >
        Gesundheit &amp; Ernährung
      </AppCheckbox>

      <AppCheckbox
        v-model="value.topics_5"
        :value="1"
        :uncheckValue="null"
        @change="$emit('change', value)"
      >
        Geld &amp; Finanzen
      </AppCheckbox>

      <AppCheckbox
        v-model="value.topics_6"
        :value="1"
        :uncheckValue="null"
        @change="$emit('change', value)"
      >
        Mobilität &amp; Verkehr
      </AppCheckbox>

      <AppCheckbox
        v-model="value.topics_7"
        :value="1"
        :uncheckValue="null"
        @change="$emit('change', value)"
      >
        Wissenschaft &amp; Forschung
      </AppCheckbox>

      <AppCheckbox
        v-model="value.topics_8"
        :value="1"
        :uncheckValue="null"
        @change="$emit('change', value)"
      >
        Kunst &amp; Kultur
      </AppCheckbox>

      <AppCheckbox
        v-model="value.topics_10"
        :value="1"
        :uncheckValue="null"
        @change="$emit('change', value)"
      >
        Energie &amp; Umwelt
      </AppCheckbox>

      <AppCheckbox
        v-model="value.topics_11"
        :value="1"
        :uncheckValue="null"
        @change="$emit('change', value)"
      >
        Natur &amp; Nachhaltigkeit
      </AppCheckbox>

      <AppCheckbox
        v-model="value.topics_9"
        :value="1"
        :uncheckValue="null"
        @change="$emit('change', value)"
      >
        Medien &amp; Unterhaltung
      </AppCheckbox>

      <AppCheckbox
        v-model="value.topics_12"
        :value="1"
        :uncheckValue="null"
        @change="$emit('change', value)"
      >
        Sport &amp; Bewegung
      </AppCheckbox>

      <AppCheckbox
        v-model="value.topics_19"
        :value="1"
        :uncheckValue="null"
        @change="checkOther"
      >
        Anderes, und zwar:
      </AppCheckbox>

      <AppInput
        v-model="value.topics_other"
        :disabled="!value.topics_19"
        placeholder="andere Themen"
        @input="$emit('change', value)"
      />
    </div>

  </SlideWrapper>
</template>


<script>
import SlideWrapper from './SlideWrapper'

export default {

  name: 'SignupTopics',

  components: {
    SlideWrapper,
  },

  data(){
    return {
      value: this.getValue(),
    }
  },

  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    modelValue: Object,
  },

  watch: {

    modelValue(newVal, oldVal){

      this.value = this.getValue();
    },
  },

  methods: {

    getValue(){

      return Object.assign({}, this.$props.modelValue);
    },

    async clickNext(){

      if (await this.checkVal())
        this.$emit('next');
    },

    checkOther(){

      if (!this.value.topics_19)
        this.value.topics_other = '';

      this.$emit('change', this.value)
    },

    checkVal(){

      let oneSelected = false;

      for (let [key, val] of Object.entries(this.value)) {

        if (/topics_\d/.test(key) && val)
          oneSelected = true;
      }

      if (!oneSelected) {

        this.appError(
          'Bitte wählen Sie wenigstens einen Themenbereich aus.', 
          null, 
          'signup'
        );

        return false;
      }

      if (this.value.topics_19 && !this.value.topics_other) {

        this.appError(
          'Bitte tragen Sie ein, für welche anderen Themenfelder Sie sich besonders interessieren.',
          null, 
          'signup'
        );

        return false;
      }
      
      return true;
    },
  },
}
</script>


<style scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 6px;
  row-gap: 3px;
  align-items: end;
}
@media only screen and (max-width: 640px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
</style>