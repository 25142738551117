<template>
  <SlideWrapper
    @next="clickNext"
    @back="$emit('back')"
  >

    <h3>
      Bitte vergeben Sie einen Benutzernamen und ein Passwort für Ihren Zugang 
      zu forsa.omninet.
    </h3>

    <div>
      Als Teilnehmer bei forsa.omninet erhalten Sie einen Zugang zum 
      Mitglieder-Portal. Dort können Sie jederzeit Ihre Daten überprüfen und 
      ändern, Ihren Punktestand einsehen sowie auf neue Umfragen zugreifen. 
      Außerdem erhalten Sie als Mitglied im forsa.omninet-Panel einen 
      exklusiven Einblick in die neuesten Ergebnisse der von forsa 
      durchgeführten Meinungs- und Bevölkerungsumfragen.
    </div>

    <AppInput
      v-model="value.username"
      placeholder="Benutzername"
      @input="$emit('change', value)"
    >
      Benutzername:
    </AppInput>
    
    <AppPasswordInput
      v-model="value.password"
      placeholder="Passwort"
      @input="$emit('change', value)"
      @enterPressed="clickNext"
    >
      Passwort:
    </AppPasswordInput>
    
  </SlideWrapper>
</template>


<script>
import SlideWrapper from './SlideWrapper'

export default {

  name: 'SignupCredentials',

  components: {
    SlideWrapper,
  },

  data(){
    return {
      value: this.getValue(),
    }
  },

  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    modelValue: Object,
  },

  watch: {

    modelValue(newVal, oldVal){

      this.value = this.getValue();
    },
  },

  methods: {

    getValue(){

      return Object.assign({}, this.$props.modelValue);
    },

    async clickNext(){

      if (await this.checkVal())
        this.$emit('next');
    },

    async checkVal(){

      if (!this.value.username) {

        this.appError('Bitte vergeben Sie einen Benutzernamen.', null, 'signup')

        return false;
      }

      if (!this.value.password) {

        this.appError('Bitte vergeben Sie ein Passwort für Ihren Zugang zu forsa.omninet.', null, 'signup')

        return false;
      }

      if (this.value.password.length < 5) {

        this.appError('Das Passwort für Ihren Zugang zu forsa.omninet muss aus mindestens 5 Zeichen bestehen.', null, 'signup')

        return false;
      }

      this.appWorking('signup');
      
      return await $.ajax({
        method: 'POST',
        url: this.urls.api + 'check-username-duplicate',
        data: {
          input: this.value.username,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .then(
        succ => {

          return true;
        },

        fail => {

          this.appError('Der gewünschte Benutzername ist nicht mehr verfügbar. Bitte wählen Sie einen anderen Benutzernamen.', null, 'signup');

          return false;
        }
      );
    },
  },
}
</script>


<style scoped>
.input {
  display: grid;
  grid-template-columns: minmax(20%, 144px) auto;
  grid-column-gap: 6px;
}
</style>