<template>
  <SlideWrapper
    @next="clickNext"
    @back="$emit('back', value.anonymous ? 2 : 1)"
  >

    <h1>
      Perfekt!
    </h1>

    <div>
      Für Ihre Teilnahme an unseren Online-Umfragen erhalten Sie jeweils 
      Punkte, die wir Ihnen zwei Mal im Jahr automatisch auszahlen.
    </div>

    <div>
      Sie können dabei wählen, ob Sie Ihre Bonuspunkte in Einkaufsgutscheine, 
      Lose der &bdquo;Aktion Mensch&ldquo; oder Spenden für &bdquo;Unicef&ldquo; 
      oder &bdquo;SOS-Kinderdorf&ldquo; eintauschen wollen.
    </div>

    <h3>
      Wie möchten Sie Ihre Bonuspunkte verwenden?
    </h3>

    <div>
      <AppRadio
        v-model="value.incentive_model"
        :value="3"
        @change="$emit('change', value)"
      >
        Universal Einkaufsgutschein
      </AppRadio>

      <AppRadio
        v-model="value.incentive_model"
        :value="2"
        @change="$emit('change', value)"
      >
        Lose für &bdquo;Aktion Mensch&ldquo;
      </AppRadio>

      <AppRadio
        v-model="value.incentive_model"
        :value="4"
        @change="$emit('change', value)"
      >
        Spende an &bdquo;Unicef&ldquo;
      </AppRadio>

      <AppRadio
        v-model="value.incentive_model"
        :value="5"
        @change="$emit('change', value)"
      >
        Spende an &bdquo;SOS-Kinderdorf&ldquo;
      </AppRadio>
    </div>

  </SlideWrapper>
</template>


<script>
import SlideWrapper from './SlideWrapper'

export default {

  name: 'SignupIncentiveModel',

  components: {
    SlideWrapper,
  },

  data(){
    return {
      value: this.getValue(),
    }
  },

  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    modelValue: Object,
  },

  watch: {

    modelValue(newVal, oldVal){

      this.value = this.getValue();
    },
  },

  methods: {

    getValue(){

      return Object.assign({}, this.$props.modelValue);
    },

    async clickNext(){

      if (await this.checkVal())
        this.$emit('next');
    },

    checkVal(){

      if (!this.value.incentive_model) {

        this.appError('Bitte wählen Sie eine der Antwortoptionen aus.', null, 'signup')

        return false;
      }

      return true;
    },
  },
}
</script>


<style scoped>
.radio {
  margin-top: 3px;
}
</style>