<template>
  <SlideWrapper
    @next="clickNext"
    @back="$emit('back')"
  >

    <h1>
      Bevor wir es vergessen:
    </h1>

    <h3>
      Wie sollen wir Sie ansprechen?
    </h3>

    <AppInput
      v-model="value.first_name"
      placeholder="Vorname"
      @input="$emit('change', value)"
      @enterPressed="clickNext"
      :disabled="!!value.anonymous"
    >
      Vorname:
    </AppInput>

    <AppCheckbox
      v-model="value.anonymous"
      :value="1"
      :uncheckValue="null"
      @change="toggleFirstName"
    >
      Ich möchte lieber anonym bleiben
    </AppCheckbox>
    
  </SlideWrapper>
</template>


<script>
import SlideWrapper from './SlideWrapper'

export default {

  name: 'SignupFirstName',

  components: {
    SlideWrapper,
  },

  data(){
    return {
      value: this.getValue(),
    }
  },

  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    modelValue: Object,
  },

  watch: {

    modelValue(newVal, oldVal){

      this.value = this.getValue();
    },
  },

  methods: {

    getValue(){

      return Object.assign({}, this.$props.modelValue);
    },

    async clickNext(){

      if (await this.checkVal())
        this.$emit('next', this.value.anonymous ? 2 : 1);
    },

    async checkVal(){

      if (!this.value.anonymous && !this.value.first_name) {

        this.appError('Bitte geben Sie Ihren Vornamen in das Eingabefeld ein oder markieren Sie die Option zur anonymen Ansprache.', null, 'signup')

        return false;
      }

      return true;
    },

    toggleFirstName(){

      if (this.value.anonymous) {

        this.value.first_name = '';

        this.value.last_name = '';
      }

      this.$emit('change', this.value)
    },
  },
}
</script>


<style scoped>
.input {
  display: grid;
  grid-template-columns: minmax(20%, 144px) auto;
  grid-column-gap: 6px;
}
</style>