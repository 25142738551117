<template>
  <SlideWrapper
    @next="$emit('next')"
    class="noBack"
  >

    <h1
      v-if="!continued"
    >
      Willkommen bei forsa!
    </h1>

    <h1
      v-else
    >
      Willkommen zurück!
    </h1>

    <div
      v-if="continued"
    >
      Die Registrierung bei forsa.omninet ist noch nicht ganz abgeschlossen, 
      Ihre bisherigen Angaben haben wir aber für Sie aufgehoben.
    </div>

    <div>
      Bei forsa.omninet können Sie regelmäßig an aktuellen Umfragen teilnehmen. 
      Die Fragen können Sie jederzeit bequem an einem beliebigen PC, Laptop, 
      Tablet oder Smartphone beantworten. Ihre Antworten werden Teil eines 
      repräsentativen Meinungsbildes, das forsa der Öffentlichkeit und den 
      Entscheidungsträgern in Politik, Wirtschaft und Medien zur Verfügung 
      stellt.
    </div>

    <div>
      Sie gehen keinerlei Verpflichtung ein und bekommen für Ihre Teilnahme 
      wertvolle Punkte gut geschrieben, die Sie in Einkaufsgutscheine oder 
      Lotterielose umtauschen oder für einen guten Zweck spenden können.
    </div>

  </SlideWrapper>
</template>


<script>
import SlideWrapper from './SlideWrapper'

export default {

  name: 'SignupFinish',

  components: {
    SlideWrapper,
  },

  props: {
    continued: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
