<template>
  <SlideWrapper
    @next="clickNext"
    @back="$emit('back')"
  >

    <h1>
      Noch ein paar wenige Fragen:
    </h1>

    <h3>
      An welche E-Mail-Adresse sollen wir Ihnen Einladungen zu neuen 
      forsa-Umfragen schicken?
    </h3>

    <AppInput
      v-model="value.email"
      placeholder="E-Mail-Adresse"
      @input="$emit('change', value)"
      @enterPressed="clickNext"
    >
      E-Mail-Adresse:
    </AppInput>
    
  </SlideWrapper>
</template>


<script>
import SlideWrapper from './SlideWrapper'

export default {

  name: 'SignupEmail',

  components: {
    SlideWrapper,
  },

  data(){
    return {
      value: this.getValue(),
    }
  },

  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    modelValue: Object,
  },

  watch: {

    modelValue(newVal, oldVal){

      this.value = this.getValue();
    },
  },

  methods: {

    getValue(){

      return Object.assign({}, this.$props.modelValue);
    },

    async clickNext(){

      if (await this.checkVal())
        this.$emit('next');
    },

    async checkVal(){

      if (!this.value.email) {

        this.appError('Bitte geben Sie Ihre E-Mail-Adresse in das Eingabefeld ein.', null, 'signup')

        return false;
      }

      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!re.test(this.value.email.toLowerCase())) {

        this.appError('Die eingegebene E-Mail-Adresse ist ungültig. Bitte überprüfen Sie die Eingabe und versuchen Sie es erneut.', null, 'signup')

        return false;
      }

      this.appWorking('signup');
      
      return await $.ajax({
        method: 'POST',
        url: this.urls.api + 'check-email-duplicate',
        data: {
          input: this.value.email,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .then(
        succ => {

          return true;
        },
        
        fail => {

          this.appError('Die eingegebene E-Mail-Adresse ist bereits bei forsa.omninet registriert. Bitte verwenden Sie eine andere E-Mail-Adresse oder wenden Sie sich an unser Support-Team, um Ihren Account zu reaktivieren.', null, 'signup');

          return false;
        }
      );
    },
  },
}
</script>


<style scoped>
.input {
  display: grid;
  grid-template-columns: minmax(20%, 144px) auto;
  grid-column-gap: 6px;
}
</style>