<template>
  <div class="slide">

    <HorizontalContainer>

      <div>
        <StatusMessage
          v-if="scopedStatus.signup > 1"
          scope="signup"
        />
        <slot></slot>
      </div>

      <div
        class="buttonContainer"
      >
        <AppButton
          @click="$emit('back')"
          class="back"
        >
          Zurück
        </AppButton>

        <AppButton
          @click="$emit('next')"
          cls="material"
          class="next"
        >
          Weiter
        </AppButton>
      </div>

    </HorizontalContainer>

  </div>
</template>


<script>
import HorizontalContainer from '@/components/wrapper/HorizontalContainer'

export default {

  name: 'SlideWrapper',

  components: {
    HorizontalContainer,
  },
}
</script>


<style>
#signup .slide > .hContainer > div:first-of-type > *:not(.popupFullscreen) {
  margin: 0 0 24px 0;
  max-width: 800px;
  hyphens: auto;
}
</style>


<style scoped>
.slide,
.slide > .hContainer {
  display: flex;
  flex-direction: column;
}
.slide > .hContainer {
  padding: 48px 12px 24px 12px;
  flex-grow: 1;
}
.slide > .hContainer > div:last-of-type {
  flex-grow: 1;
}
.slide .buttonContainer {
  padding: 12px;
  max-width: 800px;
}
.slide .buttonContainer .button {
  display: inline-block;
}
.slide .button.next {
  float: right;
}
.slide .button >>> button > div {
  min-height: 42px;
  line-height: 42px;
  min-width: 144px;
}
@media only screen and (max-width: 640px) {
  .slide .button >>> button > div {
    min-height: 36px;
    line-height: 36px;
    min-width: 108px;
  }
}
.slide .button >>> button > div {
  position: relative;
}
.slide .button >>> button > div::before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 20px;
  position: absolute;
}
.slide .button.next >>> button > div {
  padding-right: 36px;
}
.slide .button.next >>> button > div::before {
  content: '\f054';
  right: 12px;
}
.slide .button.back >>> button {
  border-radius: 2px;
}
.slide .button.back >>> button > div {
  padding-left: 36px;
}
.slide .button.back >>> button > div::before {
  content: '\f053';
  left: 12px;
}
.slide.noBack .button.back {
  display: none;
}
</style>