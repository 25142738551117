<template>
  <SlideWrapper
    @next="clickNext"
    @back="$emit('back')"
  >

    <h3>
      Welchen höchsten Schul- bzw. Hochschulabschluss haben Sie?
    </h3>

    <div>
      <AppRadio
        v-model="value.education"
        :value="0"
        @change="$emit('change', value)"
      >
        noch Schülerin / Schüler
      </AppRadio>

      <AppRadio
        v-model="value.education"
        :value="1"
        @change="$emit('change', value)"
      >
        ohne Haupt-/Volksschulabschluss
      </AppRadio>

      <AppRadio
        v-model="value.education"
        :value="2"
        @change="$emit('change', value)"
      >
        Haupt-/Volksschulabschluss
      </AppRadio>

      <AppRadio
        v-model="value.education"
        :value="3"
        @change="$emit('change', value)"
      >
        Realschulabschluss (Mittlere Reife)
      </AppRadio>

      <AppRadio
        v-model="value.education"
        :value="4"
        @change="$emit('change', value)"
      >
        Abschluss der Polytechnischen Oberschule (8./10. Klasse)
      </AppRadio>

      <AppRadio
        v-model="value.education"
        :value="5"
        @change="$emit('change', value)"
      >
        Fachhochschulreife
      </AppRadio>

      <AppRadio
        v-model="value.education"
        :value="6"
        @change="$emit('change', value)"
      >
        allgemeine oder fachgebundene Hochschulreife/Abitur
      </AppRadio>

      <AppRadio
        v-model="value.education"
        :value="7"
        @change="$emit('change', value)"
      >
        Fach-/Hochschulstudium
      </AppRadio>

      <AppRadio
        v-model="value.education"
        :value="8"
        @change="$emit('change', value)"
      >
        anderer Schulabschluss
      </AppRadio>

      <AppRadio
        v-model="value.education"
        :value="9"
        @change="$emit('change', value)"
        style="margin-top: 12px; font-style: italic; color: #666;"
      >
        keine Angabe
      </AppRadio>
    </div>

  </SlideWrapper>
</template>


<script>
import SlideWrapper from './SlideWrapper'

export default {

  name: 'SignupEducation',

  components: {
    SlideWrapper,
  },

  data(){
    return {
      value: this.getValue(),
    }
  },

  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    modelValue: Object,
  },

  watch: {

    modelValue(newVal, oldVal){

      this.value = this.getValue();
    },
  },

  methods: {

    getValue(){

      return Object.assign({}, this.$props.modelValue);
    },

    async clickNext(){

      if (await this.checkVal())
        this.$emit('next');
    },

    checkVal(){

      if (this.value.education === null || this.value.education === false) {

        this.appError('Bitte wählen Sie eine der Antwortoptionen aus.', null, 'signup')

        return false;
      }

      return true;
    },
  },
}
</script>


<style scoped>
.radio {
  margin-top: 3px;
}
</style>