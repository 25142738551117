<template>
  <SlideWrapper
    @next="clickNext"
    @back="$emit('back')"
  >

    <h3>
      Bitte verraten Sie uns Ihr Geschlecht und wann Sie geboren sind.
    </h3>

    <div>
      <AppRadio
        v-model="value.gender"
        :value="2"
        @change="$emit('change', value)"
      >
        weiblich
      </AppRadio>
      <AppRadio
        v-model="value.gender"
        :value="1"
        @change="$emit('change', value)"
      >
        männlich
      </AppRadio>
    </div>

    <AppInput
      type="tel"
      v-model="value.birthyear"
      maxlength="4"
      placeholder="Geburtsjahr (4-stellig)"
      @input="$emit('change', value)"
      @enterPressed="clickNext"
    >
      Geburtsjahr:
    </AppInput>
    
  </SlideWrapper>
</template>


<script>
import SlideWrapper from './SlideWrapper'

export default {

  name: 'SignupPerson',

  components: {
    SlideWrapper,
  },

  data(){
    return {
      value: this.getValue(),
    }
  },

  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    modelValue: Object,
  },

  watch: {

    modelValue(newVal, oldVal){

      this.value = this.getValue();
    },
  },

  methods: {

    getValue(){

      return Object.assign({}, this.$props.modelValue);
    },

    async clickNext(){

      if (await this.checkVal())
        this.$emit('next');
    },

    checkVal(){

      if (!this.value.gender) {

        this.appError('Bitte wählen Sie Ihr Geschlecht aus.', null, 'signup')

        return false;
      }

      if (!this.value.birthyear || parseInt(this.value.birthyear) < 1900) {

        this.appError('Bitte tragen Sie Ihr Geburtsjahr in vierstelliger Form ein.', null, 'signup')

        return false;
      }

      if (parseInt(this.value.birthyear) > this.moment().year()) {

        this.appError('Bitte tragen Sie ein gültiges Geburtsjahr ein.', null, 'signup')

        return false;
      }

      if (parseInt(this.value.birthyear) > this.moment().year() - 14) {

        this.appError('Für die Teilnahme an forsa.omninet müssen Sie mindestens 14 Jahre alt sein.', null, 'signup')

        return false;
      }
      
      return true;
    },
  },
}
</script>


<style scoped>
.input {
  display: grid;
  grid-template-columns: minmax(20%, 144px) auto;
  grid-column-gap: 6px;
}
</style>