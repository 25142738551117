<template>
  <SlideWrapper
    @next="clickNext"
    @back="$emit('back')"
  >

    <h1>
      Willkommen, {{ value.first_name }}!
    </h1>

    <h3>
      Wie lautet Ihr Nachname?
    </h3>

    <AppInput
      v-model="value.last_name"
      placeholder="Nachname"
      @input="$emit('change', value)"
      @enterPressed="clickNext"
      :disabled="!!value.first_name_only"
    >
      Nachname:
    </AppInput>

    <AppCheckbox
      v-model="value.first_name_only"
      :value="1"
      :uncheckValue="null"
      @change="toggleLastName"
    >
      Bitte sprechen Sie mich nur mit meinem Vornamen an
    </AppCheckbox>
    
  </SlideWrapper>
</template>


<script>
import SlideWrapper from './SlideWrapper'

export default {

  name: 'SignupLastName',

  components: {
    SlideWrapper,
  },

  data(){
    return {
      value: this.getValue(),
    }
  },

  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    modelValue: Object,
  },

  watch: {

    modelValue(newVal, oldVal){

      this.value = this.getValue();
    },
  },

  methods: {

    getValue(){

      return Object.assign({}, this.$props.modelValue);
    },

    async clickNext(){

      if (await this.checkVal())
        this.$emit('next');
    },

    async checkVal(){

      if (!this.value.first_name_only && !this.value.last_name) {

        this.appError(
          'Bitte geben Sie Ihren Familiennamen in das Eingabefeld ein oder markieren Sie die Option zur anonymen Ansprache.', 
          null, 
          'signup'
        );

        return false;
      }

      return true;
    },

    toggleLastName(){

      if (this.value.first_name_only)
        this.value.last_name = '';

      this.$emit('change', this.value)
    },
  },
}
</script>


<style scoped>
.input {
  display: grid;
  grid-template-columns: minmax(20%, 144px) auto;
  grid-column-gap: 6px;
}
</style>